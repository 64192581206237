import Layouts from '@/components/layouts/LayoutsView.vue'
const FinancialList = () => import('@/views/financialManagement/FinancialList.vue')
const lightBoardFinancialList = () => import('@/views/financialManagement/lightBoardFinancialList.vue')
const WalletList = () => import('@/views/walletManagement/WalletList.vue')
const ReceiptList = () => import('@/views/financialManagement/ReceiptList.vue')

export default [
  {
    path: '/financial',
    name: '财务管理',
    component: Layouts,
    meta: {
      title: '财务管理'
    },
    children: [
      {
        path: 'financialList',
        name: '团购财务结算',
        meta: {
          title: '团购财务结算'
        },
        component: FinancialList
      },
      {
        path: 'lightBoardFinancialList',
        name: '灯牌商城结算',
        meta: {
          title: '灯牌商城财务结算'
        },
        component: lightBoardFinancialList
      },
      {
        path: 'walletList',
        name: '钱包管理',
        meta: {
          title: '钱包管理'
        },
        component: WalletList
      },
      {
        path: 'receiptList',
        name: '发票管理',
        meta: {
          title: '发票管理'
        },
        component: ReceiptList
      }
    ]
  }
]
